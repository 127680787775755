import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiRequestService } from "../../../services/api-request.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { NotificationsService, NotificationType } from "angular2-notifications";

@Component({
  selector: "app-users-add-old-plan",
  templateUrl: "./users-add-old-plan.component.html",
})
export class UsersAddOldPlanComponent {
  plans: any;
  registration_code: string = null
  user_id = ''
  plan_id = new FormControl("", Validators.required);
  months = new FormControl("", [Validators.min(0), Validators.max(24), Validators.required]);
  curso = new FormControl("", Validators.required);

  planForm: FormGroup = new FormGroup({
    curso: this.curso,
    plan_id: this.plan_id,
    months: this.months
  });

  saving = false;

	dados:any;
	cursos: any[]; 
	turmasList: any[] = [];
  ativarSelecaoTurma: boolean = false;

  constructor(
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<UsersAddOldPlanComponent>,
		private formBuilder: FormBuilder,
		private notifications: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user_id = data.user.id

    this.CarregarCursos();

    if(data.curso){
      this.planForm.get('curso').setValue(data.curso.id);
      this.CarregarTurmas();
    }  
      
    if(data.waitList && data.waitList.registration_code){
      this.registration_code = data.waitList.registration_code.code;
    }
    
  }

  CarregarCursos(){
  
    this.api.Get("cursos").subscribe((response:any[])=>{
      this.cursos = response;
    }, error=>{
      console.error("ERRO", error)
    });

}


  CarregarTurmas() {

    this.dados = [];

    if(this.curso){

    const url = `cursos/turmas/`+this.curso.value;
    
    this.api.Get(url).subscribe((response:any[])=>{
      this.turmasList = response;
      if (this.turmasList.length > 0){
        this.plans = response;
        this.ativarSelecaoTurma = true;
      }else{
        this.notifications.create("Atenção:", "Turmas não encontradas. Verifique se este curso possui turmas vinculadas", NotificationType.Error, { timeOut: 4500 } );
      }
    }, error=>{
      this.turmasList = [];
      this.notifications.create("Erro!", error.message, NotificationType.Error, { timeOut: 2500 } );
    });
  }
}

  addPlan(): void {
    this.saving = true;
    let wait_list_id = this.data.waitList ? this.data.waitList.id : null;

    this.api.Post(`user/${this.user_id}/oldplan/${this.plan_id.value}`, {
      months_count: this.months.value,
      periodicity: 'monthly',
      origin: 'admin',
      voucher: this.registration_code,
      wait_list_id: wait_list_id
    }).subscribe((res:any) => {
      this.dialogRef.close(res);
    }, error=>{
      this.saving = false;
      this.notifications.create("Erro!", "Erro ao adicionar plano", NotificationType.Error, { timeOut: 2500 } );
    })
  }

  Close(): void {
    this.dialogRef.close();
  }
}
