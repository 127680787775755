import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [DatePipe]
})
export class UsersListComponent implements OnInit, AfterViewInit  {
	startDate:Date;
	finalDate:Date;
	search:string = "";
	filter:string[] = [];
	users:any = {};
	columnsToDisplay = ["name", "email", "phone", "city", "used_voucher_count", "active_plans_count", "canceled_plans_count", "trial_plans_count", "deleted_at"];

	@ViewChild(MatSort) sort: MatSort;

	constructor(
		public api: ApiRequestService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
		this.GetData();
	}

	ngAfterViewInit() {
		this.sort.sortChange.subscribe(v=>{this.GetData(0)});
	}

	GetData(pag:number = 0, pageSize:number = 25) {

		let url = "user/search?page="+(pag+1)+"&pageSize="+pageSize;
		if(this.search) url += "&q="+this.search;
		if(this.filter) url += "&filter="+this.filter.join(",");
		if(this.startDate && this.finalDate){
			let start = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
			let final = this.datePipe.transform(this.finalDate, 'yyyy-MM-dd');
			url += "&startDate="+start+"&finalDate="+final;
			
		} 

		if(this.sort.active && this.sort.direction){
			url += "&sort="+this.sort.active;
			url += "&sortDirection="+this.sort.direction;
		}

		this.api.Get(url).subscribe(res=>this.users=res);
	}

	Search() {
		if(this.search.length>=3){
			this.GetData(0, this.users ? this.users.per_page : 25);
		} 
		if(this.search.length == 0) {
			this.GetData();
		}
	}

	ChangeFilter(){
		this.GetData(0, this.users ? this.users.per_page : 25);
	}

	GetCanceledPlanData($data){
		return $data.plans.find(v=>v.status=="canceled");
	};

	public ChangePage(event?:PageEvent){
		console.log(event);
		this.GetData(event.pageIndex, event.pageSize);
	}

	limparCampo(campo: string) {
		this[campo] = '';
		this.GetData(0, this.users ? this.users.per_page : 25);
	}
}
