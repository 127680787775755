import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiRequestService } from '../../services/api-request.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'edit-journey-step-date',
  templateUrl: './edit-journey-step-date.component.html',
  styleUrls: ['./edit-journey-step-date.component.scss'],
  providers: [DatePipe]
})
export class EditJourneyStepDateComponent {
	available_at:Date;
	expires_at:Date;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<EditJourneyStepDateComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private datePipe: DatePipe

	) {
		this.available_at = dialogData.available_at;
		this.expires_at = dialogData.expires_at;

	}

	close(param: string) {
		let data;

		if (param === 'Cancelar') {
			this.dialogRef.close();
		} else if (param === 'Remover') {
			data = {
				"available_at": null,
				"expires_at": null
			};
		} else if (param === 'Salvar') {
			data = {
				"available_at":this.available_at,
				"expires_at":this.expires_at
			};
		}

		this.dialogRef.close(data);

	}
}