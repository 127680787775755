import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators, AbstractControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, debounceTime, switchMap} from 'rxjs/operators';
import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';

import { ApiRequestService } from '../../../services/api-request.service';
import { ValidateEmailExist } from '../../../utils/ValidateEmailExist';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { HttpClient } from '@angular/common/http';
import { GenericValidator } from 'src/app/utils/generic-validator';


interface Cidade {
	id:number;
	name: string;
}
interface Estado {
	id:number;
	name: string;
	sigla: string;
}

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent implements OnInit {

	usuario:User = new User();

	cadastro:any = {
		info: {}
	};
	opcoes:any = [];
	estados:any = [];
	cidades:any = [];
	filteredCidades:any;
	filteredEstados:any;
	validateEmailExist:ValidateEmailExist = new ValidateEmailExist(this.api);

	enviandoForm:boolean = false;
	editando:boolean = false;

	formCadastro:FormGroup= new FormGroup({
		name: new FormControl('', [Validators.required, GenericValidator.isValidName()]),
		social_name: new FormControl(''),
		email: new FormControl('', [Validators.required, Validators.email], this.validateEmailExist.CheckEmailTaken.bind(this.validateEmailExist)),
		password: new FormControl(''),
		password2: new FormControl('', this.CheckPassword),
		phone: new FormControl('', Validators.required),
		gender: new FormControl('', Validators.required),
		marital_status: new FormControl(''),
		birthdate: new FormControl(''),
		parent_name: new FormControl(''),
		nationality: new FormControl(''),
		cpf: new FormControl('', Validators.required),
		identity: new FormControl(''),
		rg_uf: new FormControl(''),
		rg_emissor: new FormControl(''),
		address: new FormControl(''),
		street_number: new FormControl(''),
		district: new FormControl(''),
		zip_code: new FormControl('', Validators.required),
		state: new FormControl('', Validators.required),
		city: new FormControl('', Validators.required),
		complement: new FormControl(''),
		instructor: new FormControl(false),
		responsable_name: new FormControl(''),
		responsable_cpf: new FormControl(''),
		responsable_phone: new FormControl(''),
		responsable_email: new FormControl('', [Validators.email]),
	});

	config:any = Config;
	cepData:any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService,
		private http: HttpClient

	) {
	}

	CheckPassword(control: AbstractControl): { [key: string]: boolean } | null {

	    if (control.parent!=undefined && control.parent.get('password').value!=control.parent.get('password2').value) {
	        return { 'pwdMatch': true };
	    }
	    return null;
	}

	async ngOnInit() {

		this.route.params.subscribe(ret => {
			if(ret.id!=undefined){
				this.CarregarDados(ret.id);
			}
		});

		await this.CarregarEstados();
		this.setupAutoCompleteEstados();
	}



	dados;
	CarregarDados(id){

		this.editando = true;

		this.formCadastro = new FormGroup({
			name: new FormControl('', Validators.required),
			email: new FormControl('', [Validators.required, Validators.email]),
			phone: new FormControl('', Validators.required),
			gender: new FormControl('', Validators.required),
			birthdate: new FormControl(''),
			cpf: new FormControl(''),
			address: new FormControl(''),
			street_number: new FormControl(''),
			district: new FormControl(''),
			zip_code: new FormControl(''),
			state: new FormControl('', Validators.required),
			city: new FormControl('', Validators.required),

			password: new FormControl(''),
			password2: new FormControl('', [this.CheckPassword])
		});

		if(id=="me") id = this.usuario.data.user.id;

		this.api.Get("../user/"+id).subscribe((res:any)=>{
			this.dados = res;

			if(res.info){

				let dataFormatada;
				if(res.info.birthdate){
					let data = res.info.birthdate.split("-");
					dataFormatada = data[2]+"/"+data[1]+"/"+data[0];
				} else {
					dataFormatada = "";
				}

				this.formCadastro.setValue({
					name: res.name,
					email: res.email,
					phone: res.info.phone,
					gender: res.info.gender,
					birthdate: dataFormatada,
					cpf: res.info.cpf,
					address: res.info.address,
					street_number: res.info.street_number,
					district: res.info.district,
					zip_code: res.info.zip_code,
					state: res.info.state,
					city: res.info.city.toString(),
					password: "",
					password2: ""
				});
			} else {
				this.formCadastro.get("name").setValue(res.name);
				this.formCadastro.get("email").setValue(res.email);
			}

			this.CarregarCidades(res.info.state);

		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		})
	}

	GetField(field:string, form:FormGroup = this.formCadastro){
		return form.get(field);
	}

	GetError(field:string, form:FormGroup = this.formCadastro){
		if(form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		} else if(form.get(field).hasError('email')){
			return "E-mail inválido";
		} else if(form.get(field).hasError('minlength')){
			return "A senha precisa ter no mínimo 6 caracteres";
		} else if(form.get(field).hasError('pwdMatch')){
			return "As senhas não coincidem";
		} else if(form.get(field).hasError('emailTaken')){
			return "Este e-mail já está cadastrado no SimSave. ";
		} else {
			return "Preencha este campo corretamente";
		}
	}


	async CarregarEstados(){

		const response = await this.api.Get("../estados").toPromise();
		this.estados = response;

	}

	async CarregarCidades(uf: string){

		const response = await this.api.Get("../cidades/" + uf).toPromise();
		this.cidades = response;

		this.setupAutoCompleteCidades();

	}

	Cadastrar(){

		if(this.formCadastro.valid){
			let v = this.formCadastro.value;

			let data = {
				name: v.name,
				email: v.email,
				password: v.password,
				info: {
					phone: v.phone,
					gender: v.gender,
					social_name: v.social_name,
					marital_status: v.marital_status,
					city: String(v.city.id),
					complement: v.complement,
					state: v.state.sigla,
					birthdate: (v.birthdate == "") ? null : v.birthdate.substr(4, 4) + "-" + v.birthdate.substr(2, 2) + "-" + v.birthdate.substr(0, 2),
					nationality: v.nationality,
					cpf: v.cpf,
					identity: v.identity,
					address: v.address,
					street_number: v.street_number,
					district: v.district,
					zip_code: v.zip_code,
					parent_name: v.parent_name,
					rg_uf: v.rg_uf,
					rg_emissor: v.rg_emissor,
					responsable_name: v.responsable_name,
					responsable_cpf: v.responsable_cpf,
					responsable_phone: v.responsable_phone,
					responsable_email: v.responsable_email
				}
      }

			let url = !this.dados ? "../user" : "../user/"+this.dados.id;

			this.enviandoForm = true;
			this.api.Post(url, data).subscribe((user: any)=>{
        		this.enviandoForm = false;
				console.log('usuario salvo', user)
        		this.router.navigate(['users', user.id])
			}, (error:any)=>{
				this.enviandoForm = false;

				this.notifications.create("Erro!", "Erro ao cadastrar usuário. Tente novamente mais tarde.", NotificationType.Error, { timeOut: 5000 } );
				console.error(error.error);

			});
		}
	}


	SalvoSucesso(){
		this.enviandoForm = false;
		this.notifications.create("Sucesso!", "Usuário salvo com sucesso", NotificationType.Success, { timeOut: 2500 } );

		this.Voltar();

	}

	async setEstado(uf: string){
		
		await this.estados.map((estado)=>{
			if (estado.sigla == uf){
				this.formCadastro.get('state').setValue(estado);
			}
		})
	}

	async setCidade(localidade: string){

		if (this.cidades.length > 0){
			await this.cidades.map((cidade)=>{
				if (cidade.name == this.cepData.localidade){
					this.formCadastro.get('city').setValue(cidade);
				}
			});
		}else{
			console.error("Erro ao definir cidade");
		}
	}

	Voltar(): void {
		this.router.navigate(['users']);
	}

	async buscaCep(cep){

		cep = cep.replace("-","")

		if (cep.length == 8){
			this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`).subscribe(
				async (response) => {
					this.cepData = response;
					await this.setEstado(this.cepData.uf);
					await this.CarregarCidades(this.cepData.uf);
					await this.setCidade(this.cepData.localidade);
					this.formCadastro.get('district').setValue(this.cepData.bairro);
					this.formCadastro.get('address').setValue(this.cepData.logradouro);
	
					
				},
				(error) => {
					console.log("Erro ao buscar: ", cep);
	
				}
			);
		}

	}



	setupAutoCompleteEstados(){

		this.filteredEstados = this.formCadastro.get('state').valueChanges
			.pipe(
				startWith<string | Estado>(''),
				map(value => typeof value === 'string' ? value : value.name),
				map(name => name ? this._filter(name, 'estado') : this.estados.slice())

			);
		
	}

	setupAutoCompleteCidades(){

		this.filteredCidades = this.formCadastro.get('city').valueChanges
			.pipe(
				startWith<string | Cidade>(''),
				map(value => typeof value === 'string' ? value : value.name),
				map(name => name ? this._filter(name, 'cidade') : this.cidades.slice())

			);		
	}

	
	private _filter(value: string, tipo:string): any[] {

		const filterValue = value.toLowerCase();

		if(tipo == 'estado' && this.estados != undefined){
			
			return this.estados.filter(estado => estado.name.toLowerCase().includes(filterValue));

		}else if(tipo == 'cidade' && this.cidades != undefined){
			console.log("filterValue", filterValue);

			return this.cidades.filter(cidade => cidade.name.toLowerCase().includes(filterValue));
		}
	}

	displayFn(subject){
		return subject ? subject.name : "";
	}
}
